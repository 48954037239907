<template>
  <base-layout>
    <ion-searchbar show-cancel-button="focus" @ionChange="filter(search)" :value="search"
                   @ionInput="search = $event.target.value;"></ion-searchbar>
    <ion-content class="has-header has-subheader">
      <ion-list>
        <div v-for="abstract in filteredAbstracts" :key="abstract.id">
          <router-link :to="'/app/abstracts/' + abstract.id">
            <ion-item detail>
              <ion-label>
                <h2 v-html="abstract.title"></h2>
                <small v-html="abstract.authors"></small>
                </ion-label>
            </ion-item>
          </router-link>
        </div>
      </ion-list>
    </ion-content>
  </base-layout>
</template>
<script>
import {IonContent, IonSearchbar,IonItem, IonLabel} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapState} from "vuex";

export default defineComponent({
  name: "Abstracts",
  data() {
    return {
      abstracts: [],
      search: "",
      filtered: [],
    };
  },
  components: {
    IonContent,
    IonSearchbar,
    IonItem,
    IonLabel
  },
  methods: {
    ...mapActions("abstracts", ["getAllAbstracts"]),
    filter(term) {
      if (term.length > 0) {

        this.filtered = this.abstracts.filter(
            abstract => Object.keys(abstract).some(k => {
              return (abstract[k] && typeof abstract[k] == 'string' ? abstract[k].toLowerCase().includes(term.toLowerCase()) : '')
            })
        );/*.sort( ( a, b) => {
          return a.last_name.localeCompare(b.last_name);
        })*/
      } else {
        this.filtered = [];
      }
    },
  },
  computed:{
    ...mapState('sync', ['updateFinished']),
    filteredAbstracts: function () {
      let returnList = this.filtered.length ? this.filtered: this.abstracts;
      return returnList;
    }
  },

  async created() {
    this.abstracts = await this.getAllAbstracts();
  },
  watch: {
    updateFinished: async function (newVal) {
      if (newVal) {
        this.abstracts = await this.getAllAbstracts();
      }
    },
  },
});
</script>
<style lang="scss" scoped>
a {
  text-decoration: none
}
</style>